import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "src/components/core/layout/layout"
import Img from "gatsby-image"
import Card from "src/components/core/card"
import CardContent from "src/components/core/card-content"
import styled from "styled-components"
import BodyImage from "src/components/core/body-image"
import Spacer from "src/components/core/spacer"

const Title = styled.h1`
  font-weight: normal;
  font-size: 2rem;
  font-family: "Crimson Text", "serif";
`

const Subtitle = styled.h3`
  font-weight: normal;
  font-size: 2rem;
`

const CoverWrapper = styled.div`
  ${({ coverstyle, coverdimension }) => {
    if (coverstyle === "fullwidth") {
      return `
            width: 100%;
            `
    } else if (coverstyle == "setheight") {
      return `
      max-width: 100%;
            width: auto;
            height: ${coverdimension}rem;
            margin: auto;
            `
    } else if (coverstyle == "setwidth") {
      return `
      max-width: 100%;
            height: auto;
            width: ${coverdimension}rem;
            margin: auto;
            `
    }
  }}
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  img {
    height: 100%;
    width: 100%;
  }
`

const BodyText = styled.div`
    line-height: 2.1rem;
    p{
        @media screen and (min-width: 1200px){
            text-align: justify;
            padding: 0 3.5rem;   
        }
    }
  ul{
      padding: 0 1rem;
    @media screen and (min-width: 1200px) {
      text-align: justify;
      padding: 0 4.5rem;
    }
  }
    .block-img{
        text-align: center;
        img{
            margin: auto;
        }
    }
`
const Book = ({ data, className, coverstyle, coverdimension }) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>setLoaded(true), [])
    //   const { data } = prismicBook
  //   const {tags} = allPrismicTag
  //   const {posts} = allPrismicPost
  const prismicBook = data.prismicBook
  const allPrismicTag = data.allPrismicTag
  const allPrismicPost = data.allPrismicPost
  const bookTag = data.prismicBook.data.book_tag
    ? data.prismicBook.data.book_tag.document[0].prismicId
    : null
  const relatedPosts = allPrismicPost.nodes.filter(post => {
    return post.data.tags.some(postTag => {
      return (
        bookTag && postTag.tag && postTag.tag.document[0].prismicId === bookTag
      )
    })
  })
  const sidebar = prismicBook.data.sidebartext
    ? prismicBook.data.sidebartext.html
    : null
    return !loaded ? <Layout></Layout> : (
    <Layout
    title={prismicBook.data.title ? prismicBook.data.title.text : null}
    bookLink={prismicBook.data.link ? prismicBook.data.link.url : null}
      bookCover={
        prismicBook.data.bookcover && prismicBook.data.bookcover.localFile && prismicBook.data.bookcover.localFile.childImageSharp
          ? prismicBook.data.bookcover.localFile.childImageSharp.fluid
          : null
      }
      sidebarimage={
        prismicBook.sidebarimage &&  prismicBook.sidebarimage &&prismicBook.sidebarimage.localFile.childImageSharp
          ? prismicBook.sidebarimage.localFile.childImageSharp.fluid
          : null
      }
      sidebar={sidebar ? sidebar : null}
      relatedPosts={relatedPosts ? relatedPosts : null}
      toptags={false}
    >
      <Spacer />
      <Card>
        <CardContent>
          <Title>{prismicBook.data.title.text}</Title>
        </CardContent>
        <CoverWrapper
          className={className}
          coverstyle={
            prismicBook.data.coverstyle
              ? prismicBook.data.coverstyle
              : "setheight"
          }
          coverdimension={
            prismicBook.data.coverdimension
              ? prismicBook.data.coverdimension
              : "20"
          }
        >
            {prismicBook.data.cover && prismicBook.data.cover.localFile && prismicBook.data.cover.localFile.childImageSharp ?<Img
              fluid={prismicBook.data.cover.localFile.childImageSharp.fluid}
            /> : prismicBook.data.cover && prismicBook.data.cover.url ? <img
              src={prismicBook.data.cover.url}
            /> : null }

        </CoverWrapper>
        {prismicBook.data.body.map(bodyPart => (
          <CardContent>
            <BodyText className="body-text" dangerouslySetInnerHTML={{ __html: bodyPart.text.html }} />
            {bodyPart.image.localFile && bodyPart.image.localFile.childImageSharp ? (
              <BodyImage
                image={bodyPart.image.localFile.childImageSharp.fluid}
                imagestyle={
                  bodyPart.imagestyle ? bodyPart.imagestyle : "setheight"
                }
                imagedimension={
                  bodyPart.imagedimension ? bodyPart.imagedimension : "10"
                }
                caption={
                  bodyPart.image_caption ? bodyPart.image_caption.text : null
                }
              />
            ) : null}
          </CardContent>
        ))}
      </Card>
    </Layout>
  )
}
export default Book
export const pageQuery = graphql`
  query BookBySlug($uid: String!) {
    prismicBook(uid: { eq: $uid }) {
      data {
        abstract {
          html
        }
        body {
          image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          image_caption {
            html
            text
          }
          text {
            html
          }
          imagedimension
          imagestyle
        }
        bookcover {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        cover {
            url
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        link {
          url
        }
        book_tag {
          document {
            prismicId
          }
        }
        previewavatar {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        previewcover {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        sidebartext {
          html
        }
        title {
          text
        }
        coverdimension
        coverstyle
      }
    }
    allPrismicPost {
      nodes {
        uid
        prismicId
        data {
          subtitle {
            text
          }
          title {
            text
          }
          tags {
            tag {
              document {
                prismicId
              }
            }
          }
        }
      }
    }
    allPrismicTag {
      nodes {
        prismicId
      }
    }
  }
`
